import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {MainBox, MainText, SubText} from "../components/MainComponents";
import {
    MainDiv,
    Motivation,
    MySection,
    ProFeatureDescription,
    ProFeatureDetails,
    ProFeatureName,
    PText
} from "./common";
import Helmet from "react-helmet";

class IndexPageEN extends React.Component {
    constructor (props) {
        super(props)
        this.state = {mounted: false}
    }

    componentDidMount()
    {
        this.setState({mounted: true})
    }


    render() {
        const data = this.props.data;
        const {frontmatter} = data.markdownRemark

        return (
            <Layout>
                <div>
                    <div
                        className="full-width-image margin-top-0"
                        style={{
                            backgroundImage: `url("/img/layout/background-matrix.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )`,
                            backgroundPosition: `top left`,
                            height: "500px",
                        }}
                    >
                        <MainBox>
                            <MainText>Reinforce the IT Security of your<br/>systems and employees</MainText>
                            <SubText>We help individuals and organizations<br/>gain security awareness.</SubText>
                        </MainBox>
                    </div>
                    <MySection className="section section--gradient" style={{backgroundColor: "#F0F0F0"}}>
                        <div className="container">
                            <MainDiv className="section">
                                <div className="columns">
                                    <div className="column is-10 is-offset-1">
                                        <div className="content">
                                            <Motivation className="subtitle">
                                                As a non-profit TunaSec organization, we support a safer Internet and
                                                improved security for the systems you use.
                                            </Motivation>
                                            <div className="column is-12">
                                                <PText><strong>{frontmatter.description}</strong></PText>
                                            </div>
                                            <Motivation className="subtitle">
                                                WHAT WE OFFER
                                            </Motivation>
                                            <div className="column is-12" style={{margin: "0 auto"}}>
                                                <ProFeatureDetails>
                                                    <ProFeatureName>Security audit and penetration
                                                        testing</ProFeatureName>
                                                    <ProFeatureDescription>
                                                        For our customers, we provide security audit of company systems
                                                        their processes, penetration testing and consulting in area of
                                                        IT security.
                                                    </ProFeatureDescription>
                                                </ProFeatureDetails>

                                                <ProFeatureDetails>
                                                    <ProFeatureName>
                                                        We educate startups, corporations and non-profits in IT Security
                                                    </ProFeatureName>
                                                    <ProFeatureDescription>
                                                        A lot of startups don't have processes set up in the area of
                                                        their IT security. One of the examples is sharing usernames and
                                                        passwords to shared accounts on Slack, employees using their own
                                                        personal computers for work with no updates or non-existant
                                                        backups of critical systems. Some companies don't have
                                                        transparent policy of gathering data or allow usage of weak
                                                        passwords. Majority of these problems could be solved by
                                                        following a couple of basic steps and we can help you implement
                                                        them to your workflow.
                                                    </ProFeatureDescription>
                                                </ProFeatureDetails>

                                                <ProFeatureDetails>
                                                    <ProFeatureName>
                                                        Education of critical groups - sales team, lawyers, ...
                                                    </ProFeatureName>
                                                    <ProFeatureDescription>
                                                        We've identified these groups as the most vulnerable to possible
                                                        data leak and data misuse. It is crucial to keep an eye on their
                                                        education in the area of IT security.
                                                    </ProFeatureDescription>
                                                </ProFeatureDetails>

                                                <ProFeatureDetails>
                                                    <ProFeatureName>
                                                        Articles in the area of IT security for public
                                                    </ProFeatureName>
                                                    <ProFeatureDescription>
                                                        Even though our workshops are mainly in-person, we are planning
                                                        to large part of our knowledge online in the form of blogposts.
                                                        These will be later extended with webinars and podcasts. All of
                                                        this information will be present on this site for broad public
                                                        in Czech and Slovak.
                                                    </ProFeatureDescription>
                                                </ProFeatureDetails>

                                                <ProFeatureDetails>
                                                    <ProFeatureName>
                                                        Best practices for development teams
                                                    </ProFeatureName>
                                                    <ProFeatureDescription>
                                                        We can analyze the work in development team and propose
                                                        improvements from the area of IT security. This approach is also
                                                        called SSDLC - Secure Software Development Lifecycle. Everyone
                                                        of us has experience in IT area and implementation of these
                                                        practices and processes within IT teams.
                                                    </ProFeatureDescription>
                                                </ProFeatureDetails>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </MainDiv>
                        </div>
                    </MySection>
                </div>
                <Helmet titleTemplate="Get better in Cybersecurity | TunaSec">
                    {/* General tags */}
                    <title>Get better in Cybersecurity | TunaSec</title>
                    <meta name="robots" content="index, follow" />
                    <meta name="keywords" content="cybersecurity, it security, security education" />
                    <meta property="og:title" content="Get better in Cybersecurity | TunaSec"/>
                    <meta name="description" content="For more than 4 years, we're organizing education events, write blog posts and perform tests on client's websites and servers." />
                    <meta name="og:description" content="For more than 4 years, we're organizing education events, write blog posts and perform tests on client's websites and servers." />
                    <meta name="og:image" content="https://tunasec.com/img/og-images/tunasec.png" />
                </Helmet>
            </Layout>
        )
    }

}

IndexPageEN.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPageEN

export const pageQuery = graphql`
  query IndexPageENTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page-en" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
